import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';

const GlossRow = ({ listenAudio, playAudio, words, updateWord, id, removeRow }) => {
  const word = useMemo(() => words.find(w => w.id === id) || {}, [words, id]);

  const [nativeLanguage, setNativeLanguage] = useState(word.nativeWord || '');
  const [targetLanguage, setTargetLanguage] = useState(word.translatedWord || '');

  useEffect(() => {
    if (word.nativeWord !== nativeLanguage || word.translatedWord !== targetLanguage) {
      updateWord(id, nativeLanguage, targetLanguage);
    }
  }, [nativeLanguage, targetLanguage, id, updateWord, word]);

  const handleNativeLanguageChange = (e) => {
    setNativeLanguage(e.target.value);
  };

  const handleTargetLanguageChange = (e) => {
    setTargetLanguage(e.target.value);
  };

  return (
    <div>
      <input 
        type="text" 
        placeholder="Ursprungsspråk" 
        value={nativeLanguage} 
        onChange={handleNativeLanguageChange}
      />
      <input 
        type="text" 
        placeholder="Översättning" 
        value={targetLanguage} 
        onChange={handleTargetLanguageChange}
      />
      <div className="button-container">
            <button onClick={() => playAudio(nativeLanguage, targetLanguage)}>
                <FontAwesomeIcon icon={faPlay} className='icon-large' />
            </button>
            {/* <button className="listen-button" onClick={() => listenAudio(nativeLanguage, targetLanguage)}>
                <FontAwesomeIcon icon={faMicrophone} className='icon-large' />
            </button> */}
            <button className="remove-button" onClick={() => removeRow(id)}>
                <FontAwesomeIcon icon={faTrash} className='icon-large' />
            </button>
        </div>
    </div>
  );
};

export default GlossRow;
