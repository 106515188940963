import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import "./App.css";
import GlossRow from "./GlossRow";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import Menu from "./components/Menu";

function App() {
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const [count, setCount] = useState(() => {
    const storedCount = localStorage.getItem("count");
    return storedCount ? parseInt(storedCount) : 0;
  });
  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en-US";
  });

  const [voice, setVoice] = useState(null);
  const [words, setWords] = useState(() => {
    const storedWords = JSON.parse(localStorage.getItem("words"));
    if (storedWords) {
      return storedWords;
    }
    return [];
  });

  const [rate, setRate] = useState(1);
  const [voices, setVoices] = useState([]);
  const [filteredVoices, setFilteredVoices] = useState([]);

  const requestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Microphone access denied:", error);
    }
  };

  const generateGUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const updateWord = useCallback(
    (id, nativeWord, translatedWord) => {
      let newWords = [...words];
      const existingWordIndex = newWords.findIndex((word) => word.id === id);

      if (existingWordIndex !== -1) {
        newWords[existingWordIndex] = { id, nativeWord, translatedWord };
      } else {
        newWords.push({ id: generateGUID(), nativeWord, translatedWord });
      }

      setWords(newWords);
      localStorage.setItem("words", JSON.stringify(newWords));
    },
    [words]
  );

  const removeRow = (idToRemove) => {
    const newWords = words.filter((word) => word.id !== idToRemove);
    setWords(newWords);
    setCount((prevCount) => prevCount - 1);
    localStorage.setItem("words", JSON.stringify(newWords));
    localStorage.setItem("count", count - 1);
  };

  useEffect(() => {
    localStorage.setItem("count", count);
    localStorage.setItem("language", language);
    localStorage.setItem("voiceURI", voice ? voice.voiceURI : null);
  }, [count, language, voice]);

  useEffect(() => {
    const populateVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();

      if (availableVoices && availableVoices.length > 0) {
        const languagePrefix = language.substring(0, 3);
        const matchingVoices = availableVoices.filter((v) =>
          v.lang.startsWith(languagePrefix)
        );

        setVoices(availableVoices);
        setFilteredVoices(matchingVoices);

        if (matchingVoices.length > 0) {
          setVoice(matchingVoices[0]);
        }
      }
    };

    populateVoices();
    window.speechSynthesis.onvoiceschanged = populateVoices;
  }, [language]);

  const addRow = () => {
    setCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    const languagePrefix = language.substring(0, 3);
    const matchingVoices = voices.filter((v) =>
      v.lang.startsWith(languagePrefix)
    );
    setFilteredVoices(matchingVoices);

    if (matchingVoices.length > 0) {
      setVoice(matchingVoices[0]);
    }
  }, [language, voices]);

  const playAudio = (nativeText, translatedText) => {
    speechSynthesis.cancel();
    const utterance = new SpeechSynthesisUtterance(translatedText);
    utterance.lang = language;
    utterance.rate = rate;
    utterance.voice = voice;
    speechSynthesis.speak(utterance);
  };

  const listenAudio = (nativeText, translatedText) => {
    requestMicrophoneAccess();

    let SpeechRecognition =
      window.webkitSpeechRecognition || window.SpeechRecognition;
    let recognition = new SpeechRecognition();

    recognition.lang = language;
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.start();
    recognition.onresult = (event) => {
      let word = event.results[0][0].transcript;
      console.log(word);
      if (word === translatedText + ".") {
        playAudio("", "That is correct");
      } else {
        playAudio("", "That is not correct");
      }
    };

    recognition.onerror = (event) => {
      console.log("Speech Recognition Error:" + event.error);
    };
    recognition.onend = (event) => {
      console.log("Speech Recognition Ended:" + event);
    };
  };

  const openInfoModal = () => {
    setInfoModalIsOpen(true);
  };

  const closeInfoModal = () => {
    setInfoModalIsOpen(false);
  };

  return (
    <>
      {/* <Menu /> */}

      <div className="App">
        {/* <a
          href="#"
          onClick={openInfoModal}
          className="info-icon"
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="info-circle"
          />
        </a> */}

        <img
          src="img/logo.png"
          alt="EasyTalk"
        />

        <Modal
          isOpen={infoModalIsOpen}
          onRequestClose={closeInfoModal}
          contentLabel="Information Modal"
          className="Modal"
        >
          <h2>Välkommen till EasyTalk!</h2>
          <div>
            <h3>Välj språk och röst:</h3>
            <p>Välj önskat språk och röst från rullgardinsmenyerna.</p>

            <h3>Hastighet på uppläsning:</h3>
            <p>Använd reglaget för att justera hastigheten på uppläsningen.</p>

            <h3>Lägg till ord/meningar:</h3>
            <p>
              Klicka på knappen "Lägg till ord/mening" för att skapa nya rader.
            </p>
            <p>
              Skriv in ditt ursprungliga ord eller mening och dess översättning
              i de tillgängliga fälten.
            </p>

            <h3>Lyssna och utmana dig själv:</h3>
            <p>Klicka på "Spela upp" ikonen för att höra översättningen.</p>
            <p>
              Klicka på "Lyssna" ikonen, säg översättningen högt och se om du
              får det rätt!
            </p>

            <h3>Ta bort eller redigera ord/meningar:</h3>
            <p>Använd papperskorgsikonerna för att ta bort ord/meningar.</p>

            <h3>Spara ditt arbete:</h3>
            <p>
              Ditt arbete sparas automatiskt i din webbläsare så du kan
              fortsätta där du slutade nästa gång du besöker EasyTalk.
            </p>
            <hr />
            <p>
              Vi hoppas att du finner EasyTalk användbart för att förbättra dina
              språkkunskaper. Tveka inte att{" "}
              <a href="mailto:stellan@techfirst.se">kontakta oss</a> om du har
              några frågor eller förslag.
            </p>
          </div>

          <button onClick={closeInfoModal}>Stäng</button>
        </Modal>

        <label>Välj språk: </label>
        <select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option value="en-US">English</option>
          <option value="es-AR">Spanish</option>
          <option value="fr-FR">French</option>
        </select>
        <label>Välj röst: </label>
        <select
          onChange={(e) => setVoice(filteredVoices[e.target.selectedIndex])}
        >
          {filteredVoices.map((voice, index) => (
            <option
              key={index}
              value={index}
            >
              {voice.name} ({voice.lang})
            </option>
          ))}
        </select>

        <label>Hastighet på uppläsning: </label>
        <input
          type="range"
          min="0.5"
          max="1"
          step="0.1"
          value={rate}
          onChange={(e) => setRate(e.target.value)}
        />

        <hr className="divider" />

        <button
          onClick={addRow}
          className="addRow"
        >
          Lägg till ord/mening
        </button>

        <div className="words-container">
          <label>Dina ord/meningar: </label>

          {Array.from({ length: count }, (_, i) => (
            <GlossRow
              key={words[i]?.id || i} // Use the GUID if available
              id={words[i]?.id} // Pass the GUID to the child component
              words={words}
              updateWord={updateWord}
              removeRow={removeRow}
              playAudio={playAudio}
              listenAudio={listenAudio}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default App;
